import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_modal = _resolveComponent("modal")!
  const _component_notification = _resolveComponent("notification")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createVNode(_component_router_view),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.onShowModal)
          ? (_openBlock(), _createBlock(_component_modal, {
              key: 0,
              modal: _ctx.modal,
              onShowModal: _ctx.showModal
            }, null, 8, ["modal", "onShowModal"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_notification)
  ]))
}